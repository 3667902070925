.order-wrapper {
  @apply py-[2rem];
}

.order-wrapper .title-order-page {
  @apply text-[1.75rem] font-semibold mb-2;
}

.order-wrapper .block-order-list {
  @apply w-full h-full my-[0.75rem] px-[8px] py-[12px] border-[1px];
}

.order-wrapper .block-order-list .title-order {
  @apply text-center text-[1.5rem] font-medium text-white p-[0.625rem] bg-[#9CA3AF] rounded-[6px] mb-3;
}

.order-wrapper .block-order-list .title-order.wait {
  @apply bg-[#FFC107];
}

.order-wrapper .block-order-list .title-order.wait-delivery {
  @apply bg-[#FD7E14];
}

.order-wrapper .block-order-list .order-number-date {
  @apply flex mb-3;
}

.order-wrapper .block-order-list .order-number-date .image-address {
  @apply w-full h-full max-w-[6.3rem] max-h-[6.3rem] mr-3;
}

.order-wrapper .block-order-list .order-number-date .number-date .order-number {
  @apply text-[1.25rem] text-[#1A1A1A] font-semibold;
}

.order-wrapper .block-order-list .order-number-date .number-date .date {
  @apply text-[1rem] text-[#707070] font-normal;
}

.order-wrapper .block-order-list .order-items {
  @apply flex justify-between my-2 text-[1.25rem] text-[#5E6064] font-normal;
}

.order-wrapper .block-order-list .order-items .items-name {
  @apply w-full pr-2;
}

.order-wrapper .block-order-list .order-items .quantity-price {
  @apply flex justify-between w-2/4;
}

.order-wrapper .block-order-list .order-items .quantity-price .quantity {
  @apply px-2;
}

.order-wrapper .block-order-list .order-items .quantity-price .price {
  @apply pl-2;
}

.order-wrapper .block-order-list .order-items.total-orders .quantity-price .price, .order-wrapper .block-order-list .order-items.shipping .quantity-price .price {
  @apply font-medium;
}

.order-wrapper .block-order-list .order-items.total .total-text,
.order-wrapper .block-order-list .order-items.total .total-price {
  @apply text-[2rem] text-[#1A1A1A] font-semibold;
}

.order-wrapper .block-order-list .order-items.total .total-price {
  @apply text-[#FD6B35];
}

@media screen and (max-width: 1024.98px) {
  .order-wrapper {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .order-wrapper {
    @apply p-[1.5rem];
  }
  .order-wrapper .title-order-page {
    @apply text-center text-[1.25rem];
  }
  .order-wrapper .block-order-list {
    @apply pb-[2px];
  }
  .order-wrapper .block-order-list .title-order {
    @apply text-[1.25rem];
  }
  .order-wrapper .block-order-list .order-number-date .image-address {
    @apply max-w-[3.75rem] max-h-[3.75rem] mr-2;
  }
  .order-wrapper .block-order-list .order-number-date .number-date .order-number {
    @apply text-[1rem];
  }
  .order-wrapper .block-order-list .order-number-date .number-date .date {
    @apply text-[0.75rem];
  }
  .order-wrapper .block-order-list .order-items {
    @apply text-[0.875rem];
  }
  .order-wrapper .block-order-list .order-items .quantity-price {
    @apply flex-col justify-end items-end;
  }
  .order-wrapper .block-order-list .order-items.total .total-text,
.order-wrapper .block-order-list .order-items.total .total-price {
    @apply text-[1rem];
  }
}
