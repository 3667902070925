.card-map-wrapper {
    padding: 16px;
    position: relative;
    margin-top: -14px;
    background-color: white;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    /* height: calc(100vh - 540px); */
}

.card-map-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

input {
    width: 100%;
}

.address-item {
    padding-top: 1rem;
}

.card-map-content-list {
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.card-map-content-list::-webkit-scrollbar {
    display: none;
}

.text-title {
    display: flex;
    justify-content: center;
    align-items: center;
}
.address-title{
    font-weight: bold;
}

@media (max-width: 768px) {
    .card-map-content-list {
        height: calc(100vh - 500px);
    }
}
@media (max-width: 480px) {
    .card-map-content-list {
        height: calc(100vh - 820px);
    }
}