.profile-edit-wrapper {
  @apply py-[2rem] overflow-x-hidden;
}

.title-profile-edit {
  @apply text-[2.25rem] font-semibold text-[#1A1A1A] pb-6;
}

.block-avatar-profile-edit {
  @apply w-full h-full flex justify-center px-5 relative;
}

.btn-edit-avatar {
  @apply relative w-full h-full max-w-[12.75rem] bg-transparent border-transparent rounded-full p-1;
}

.avatar-profile-edit {
  @apply relative w-full h-full max-w-[12.75rem] rounded-full border-white drop-shadow-lg;
}

.btn-edit-avatar .block-icon-edit {
  @apply absolute inset-0 w-full h-fit max-w-[3.25rem] cursor-pointer m-auto;
}

.btn-edit-avatar .block-icon-edit .icon-edit {
  @apply w-full h-fit cursor-pointer;
}

.block-form-profile-edit {
  @apply relative px-[5rem] py-[1.5rem];
}

.form-control {
  @apply w-full pr-[3.5rem] mb-3;
}

.form-control.right {
  @apply pr-0 pl-[3.5rem];
}

.btn-group {
  @apply w-full h-full flex;
}

.btn-profile-edit {
  @apply text-[1.25rem] font-medium text-white bg-[#FD6B35] w-full max-w-[245px] rounded-full mx-auto;
}

@media screen and (max-width: 1024.98px) {
  .profile-edit-wrapper {
    @apply px-[2rem];
  }

  .block-form-profile-edit {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .profile-edit-wrapper {
    @apply px-[1.5rem] py-0;
  }

  .block-form-profile-edit {
    @apply px-[0.5rem];
  }

  .title-profile-edit {
    @apply text-[2rem] text-center;
  }

  .form-control {
    @apply px-0;
  }

  .form-control.right {
    @apply px-0;
  }

  .btn-edit-avatar .block-icon-edit {
    @apply hidden;
  }
}
