.block-first-open-login {
  @apply bg-white fixed top-0 z-[9990] p-[2rem] overflow-hidden w-screen h-screen flex flex-col justify-center text-center;
}

.block-first-open-login .image-logo {
  @apply flex justify-center pt-[1.2rem] pb-[1.2rem];
}

.block-first-open-login .image-logo .logo-login {
  @apply w-full h-full max-w-[11.25rem] max-h-[11.25rem];
}

.block-first-open-login .image-banner-content {
  @apply flex justify-center py-[1.3rem];
}

.block-first-open-login .title-login-top,
.block-first-open-login .title-login-bottom {
  @apply text-[24px] font-normal py-[1rem];
}

.block-first-open-login .image-banner-content .banner-login {
  @apply flex justify-center w-full h-full max-w-[31.063rem] max-h-[22.688rem];
}

.block-first-open-login .btn-link-group {
  @apply flex flex-col items-center pt-[1rem];
}

.block-first-open-login .btn-link-group .btn-link-login {
  @apply text-[1.25rem] font-medium bg-[#FD6B35] rounded-full text-white w-full py-[0.75rem] max-w-[23.875rem] no-underline;
}

.block-first-open-login .btn-link-group .link-skip {
  @apply text-[1.25rem] font-medium text-[#878787] w-full py-[1.875rem] max-w-[23.875rem];
}

@media screen and (max-width: 1368.98px) {
  .block-first-open-login .image-banner-content .banner-login {
    @apply max-w-[24.063rem] max-h-[15.688rem];
  }

  .block-first-open-login .btn-link-group .link-skip {
    @apply text-[16px] py-[1.875rem];
  }
}

@media screen and (max-width: 1024.98px) {
  .block-first-open-login .title-login-top,
  .block-first-open-login .title-login-bottom {
    @apply max-w-[48rem] mx-auto;
  }
}

@media screen and (max-width: 430.98px) {
  .block-first-open-login .image-logo .logo-login {
    @apply max-w-[9.75rem] max-h-[9.375rem];
  }

  .block-first-open-login .title-login-top,
  .block-first-open-login .title-login-bottom {
    @apply text-[16px];
  }
}

@media screen and (max-width: 375.98px) {
  .block-first-open-login .image-banner-content {
    @apply py-[0.8rem];
  }

  .block-first-open-login .title-login-top,
  .block-first-open-login .title-login-bottom {
    @apply m-0;
  }

  .block-first-open-login .btn-link-group {
    @apply my-[0.8rem];
  }

  .block-first-open-login .btn-link-group .btn-link-login {
    @apply mb-1;
  }
}

@media screen and (max-width: 375.98px) and (max-height: 667.98px) {
  .block-first-open-login {
    @apply p-[1rem];
  }

  .block-first-open-login .title-login-top,
  .block-first-open-login .title-login-bottom {
    @apply text-[0.875rem];
  }

  .block-first-open-login .btn-link-group .btn-link-login {
    @apply h-[48px] text-[16px];
  }

  .block-first-open-login .btn-link-group .link-skip {
    @apply text-[16px] my-[0.875rem];
  }

  .block-first-open-login .image-banner-content .banner-login {
    @apply flex justify-center w-full h-full max-w-[21.063rem] max-h-[12.688rem];
  }

  .block-first-open-login .image-logo .logo-login {
    @apply w-full h-full max-w-[6.25rem] max-h-[6.25rem];
  }
}
