.block-home-wrapper {
  @apply py-[2rem];
}

.block-name-user-location {
  @apply flex justify-between items-center mb-[1rem];
}

.block-home-wrapper .block-name-user-location .name-user {
  @apply text-[2rem] font-semibold;
}

.block-home-wrapper .block-name-user-location .location .btn-location {
  @apply bg-transparent border-transparent shadow-transparent text-[1.25rem] items-center;
}

.block-home-wrapper
  .block-name-user-location
  .location
  .btn-location
  .icon-location {
  @apply w-[4rem] h-[3.063rem];
}

.modal.home-page {
  @apply bg-[#B9B9B961];
}

.block-home-wrapper .block-search-location {
  @apply hidden w-full py-3;
}

.block-home-wrapper .block-search-location .input {
  @apply flex items-center gap-2;
}

.block-home-wrapper .block-search-location .input {
  @apply flex items-center gap-2;
}

.block-home-wrapper .block-location-detail {
  @apply flex items-center mb-5;
}

.block-home-wrapper .block-location-detail .icon-pin-line-orange {
  @apply w-[2.875rem] h-[2.875rem] mr-3;
}

.block-home-wrapper .block-location-detail .location-detail .title {
  @apply w-fit px-2 text-[1.5rem] text-[#FD6B35] font-medium border-b-2 border-[#FD6B35] mb-2;
}

.block-home-wrapper .block-location-detail .location-detail .detail {
  @apply text-[1.5rem] font-bold;
}

.block-home-banner {
  @apply mb-[2rem];
}

.block-home-banner .home-banner-slider {
  @apply relative w-full overflow-hidden rounded-[1rem];
}

.block-home-banner .home-banner-slider .banner-slider-item {
  @apply flex transition-transform duration-500 ease-in-out;
}

.block-home-banner .home-banner-slider .banner-slider-item .item-image {
  @apply w-full flex-shrink-0 object-cover;
}

.block-home-banner .home-banner-slider .btn-banner-slider {
  @apply absolute bottom-[2rem] right-[1.5rem] flex w-fit gap-2 py-2 px-[1rem] bg-[#FFFFFFCC] rounded-full;
}

.block-home-banner .home-banner-slider .btn-banner-slider .btn {
  @apply w-[2rem] h-[1rem] min-h-[1rem] text-transparent bg-[#10101024] border-[#FFFFFFCC];
}

.block-home-wrapper .block-product-type {
  @apply flex flex-wrap justify-between mb-[1.5rem] gap-3 text-center;
}

.block-home-wrapper .block-product-type .btn-type {
  @apply w-full h-full max-w-[9.625rem] min-h-[13.5rem] max-h-[13.5rem] p-1 hover:text-[#FD6B35] active:text-[#FD6B35] hover:bg-transparent hover:scale-[1.1] active:scale-[1.1];
}

.block-home-wrapper .block-product-type .btn-type .image-type {
  @apply w-full h-auto bg-[#FFE6D7] rounded-[1rem] p-[1.5rem];
}

.block-home-wrapper .block-product-type .btn-type .image-type .icon {
  @apply w-full h-full max-w-[5.25rem] max-h-[5.25rem] mx-auto;
}

.block-home-wrapper .block-product-type .btn-type .type-name {
  @apply text-[2.5rem] font-medium p-[1rem];
}

.block-home-wrapper .block-product-list .product-list-title-sort {
  @apply flex justify-between py-[2rem] items-center;
}

.block-home-wrapper
  .block-product-list
  .product-list-title-sort
  .product-list-title {
  @apply text-[2rem] font-medium;
}

.block-home-wrapper
  .block-product-list
  .product-list-title-sort
  .product-list-sort {
  @apply flex;
}

.block-home-wrapper
  .block-product-list
  .product-list-title-sort
  .product-list-sort
  .btn-sort
  .btn {
  @apply max-w-[5.5rem] max-h-[5.5rem] h-full min-h-0 p-[0.5rem] hover:bg-[#f5f5f5cc];
}

.block-home-wrapper
  .block-product-list
  .product-list-title-sort
  .product-list-sort
  .btn-sort
  .btn
  .icon {
  @apply w-full h-auto;
}

.block-home-wrapper
  .block-product-list
  .product-list-title-sort
  .product-list-sort
  .line {
  @apply rotate-90 w-[3rem] h-[0.25rem] rounded-full bg-[#303536] self-center;
}

.block-home-wrapper .block-product-list .product-list.rows {
  @apply grid grid-cols-4 gap-6;
}

.block-home-wrapper .block-product-list .product-list.column {
  @apply flex flex-col;
}

.rows .card-product-home-page {
  @apply w-full bg-[#F5F5F5] rounded-[0.7rem];
}

.rows .card-product-home-page .block-image-product {
  @apply py-4 px-6;
}

.rows .card-product-home-page .card-body {
  @apply py-4 px-6 gap-0;
}

.rows .card-product-home-page .card-body .card-title {
  @apply text-[1.5rem] font-medium leading-tight h-full;
}

.rows .card-product-home-page .card-body .unit-price {
  @apply text-[1.5rem] text-[#FD6B35] font-medium mt-[1rem];
}

.rows .card-product-home-page .card-body .card-actions {
  @apply w-full;
}

.rows .card-product-home-page .card-body .card-actions .btn-product-detail {
  @apply text-[1.2rem] text-[#303536] hover:text-white font-semibold w-full h-[2.5rem] min-h-[2.5rem] rounded-full bg-white hover:bg-[#FD6B35] border-[1.3px] border-[#FD6B35];
}

.column .card-product-home-page {
  @apply flex flex-row bg-[#F5F5F5] px-[2.5rem] py-[2rem] mb-[1rem] rounded-[2rem];
}

.column .card-product-home-page .block-image-product {
  @apply max-w-[16.063rem] max-h-[17rem] min-w-[8.75rem] min-h-[9.063rem];
}

.column .card-product-home-page .card-body {
  @apply px-[2rem] py-[0.5rem] gap-0;
}

.column .card-product-home-page .card-body .card-title {
  @apply text-[2rem] font-medium h-full items-start leading-tight;
}

.column .card-product-home-page .card-body .unit-price {
  @apply text-[2rem] text-[#FD6B35] font-medium;
}

.column .card-product-home-page .card-body .card-actions .btn-product-detail {
  @apply text-[1.2rem] text-[#303536] hover:text-white font-semibold w-full max-w-[14rem] h-[2.5rem] min-h-[2.5rem] rounded-full bg-white hover:bg-[#FD6B35] border-[1.3px] border-[#FD6B35];
}

.no-product {
  @apply w-full h-auto my-10 py-20 text-[2rem] font-medium text-center;
}

.block-pagination {
  @apply pt-10 pb-5 w-full justify-center;
}

.block-pagination .btn-pagination-prev {
  @apply mr-3;
}

.block-pagination .btn-pagination-next {
  @apply ml-3;
}
.block-pagination .btn-pagination-prev,
.block-pagination .btn-pagination-next {
  @apply p-0 w-[3rem] h-[3rem] !rounded-full bg-transparent border-0 hover:bg-[#f5f5f5cc];
}

.block-pagination .btn-pagination-numner {
  @apply !rounded-full bg-transparent hover:bg-[#f5f5f5cc] border-transparent hover:border-transparent hover:text-black;
}

.block-pagination .btn:is(input[type="radio"]:checked) {
  @apply text-white bg-[#FD6B35] border-[#FD6B35] hover:bg-[#FD6B35] hover:border-[#FD6B35] mx-2;
}

@media screen and (max-width: 1366.98px) {
  .block-home-wrapper {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 1280.98px) {
  .block-home-wrapper {
    @apply px-[2rem];
  }

  .block-home-wrapper .block-product-list .product-list.rows {
    @apply grid grid-cols-3 gap-4;
  }
}

@media screen and (max-width: 1024.98px) {
  .block-home-wrapper {
    @apply px-[2rem];
  }

  .block-home-wrapper .block-product-list .product-list.rows {
    @apply grid-cols-3;
  }
}

@media screen and (max-width: 834.98px) {
  .block-home-wrapper {
    @apply px-[2rem];
  }

  .block-home-wrapper .block-product-type .btn-type {
    @apply max-w-[7rem] max-h-[11rem] min-h-[11rem];
  }

  .block-home-wrapper .block-search-location {
    @apply block;
  }

  .rows .card-product-home-page .card-body .card-title,
  .rows .card-product-home-page .card-body .unit-price {
    @apply text-[1.25rem];
  }

  .column .card-product-home-page {
    @apply px-[2rem] py-[1.5rem];
  }

  .column .card-product-home-page .card-body .card-title,
  .column .card-product-home-page .card-body .unit-price {
    @apply text-[1.5rem];
  }
}

@media screen and (max-width: 430.98px) {
  .block-home-wrapper {
    @apply p-[1.5rem];
  }

  .block-home-wrapper .block-name-user-location .name-user {
    @apply text-[1rem];
  }

  .block-home-wrapper .block-name-user-location .name-user span {
    @apply text-[1.2rem];
  }

  .block-home-wrapper .block-name-user-location .location .btn-location {
    @apply text-[0.9rem] p-[0.1rem];
  }

  .block-home-wrapper
    .block-name-user-location
    .location
    .btn-location
    .icon-location {
    @apply w-[2.5rem] h-[2.5rem];
  }

  .block-search-location {
    @apply block my-2;
  }

  .block-search-location .input {
    @apply flex items-center gap-2;
  }

  .block-home-wrapper .block-location-detail .icon-pin-line-orange {
    @apply w-[1.5rem] h-[1.5rem];
  }

  .block-home-wrapper .block-location-detail .location-detail .title,
  .block-home-wrapper .block-location-detail .location-detail .detail {
    @apply text-[1rem];
  }

  .block-home-banner {
    @apply mb-[1rem];
  }

  .block-home-banner .home-banner-slider {
    @apply rounded-[0.75rem];
  }

  .block-home-banner .home-banner-slider .btn-banner-slider {
    @apply bottom-[0.5rem] right-[0.5rem] py-[0.3rem];
  }

  .block-home-banner .home-banner-slider .btn-banner-slider .btn {
    @apply w-[1rem] h-[0.5rem] min-h-[0.5rem];
  }

  .block-home-wrapper .block-product-type {
    @apply mb-[0.5rem] gap-[0.2rem];
  }

  .block-home-wrapper .block-product-type .btn-type {
    @apply max-w-[3.75rem] min-h-[5.25rem] max-h-[5.25rem];
  }

  .block-home-wrapper .block-product-type .btn-type .image-type {
    @apply rounded-[0.5rem] p-[0.5rem];
  }

  .block-home-wrapper .block-product-type .btn-type .type-name {
    @apply text-[1rem] p-[0.5rem];
  }

  .block-home-wrapper .block-product-list .product-list-title-sort {
    @apply py-[1rem];
  }

  .block-home-wrapper
    .block-product-list
    .product-list-title-sort
    .product-list-title {
    @apply text-[1.3rem];
  }

  .block-home-wrapper
    .block-product-list
    .product-list-title-sort
    .product-list-sort {
    @apply max-h-[1.5rem];
  }

  .block-home-wrapper
    .block-product-list
    .product-list-title-sort
    .product-list-sort
    .btn-sort
    .btn {
    @apply max-w-[1.5rem] max-h-[1.5rem] h-[1.5rem] min-h-[1.5rem] p-0 mx-2;
  }

  .block-home-wrapper
    .block-product-list
    .product-list-title-sort
    .product-list-sort
    .line {
    @apply w-[1rem] h-[0.125rem] rounded-none;
  }

  .block-home-wrapper .block-product-list .product-list.rows {
    @apply grid-cols-2;
  }

  .rows .card-product-home-page .block-image-product,
  .rows .card-product-home-page .card-body {
    @apply py-2 px-3;
  }

  .rows .card-product-home-page .card-body .card-title {
    @apply text-[0.875rem] leading-normal;
  }

  .rows .card-product-home-page .card-body .unit-price {
    @apply text-[1rem] my-[0.5rem];
  }

  .rows .card-product-home-page .card-body .card-actions .btn-product-detail {
    @apply text-[0.75rem] h-[1.75rem] min-h-[1.75rem];
  }

  .column .card-product-home-page {
    @apply px-[0.5rem] py-[0.75rem] rounded-lg mb-[0.5rem];
  }

  .column .card-product-home-page .card-body {
    @apply pr-1 pl-4;
  }

  .column .card-product-home-page .card-body .card-title,
  .column .card-product-home-page .card-body .unit-price {
    @apply text-[0.875rem];
  }

  .column .card-product-home-page .card-body .card-actions .btn-product-detail {
    @apply text-[0.75rem] h-[1.75rem] min-h-[1.75rem];
  }

  .no-product {
    @apply my-5 py-10 text-[1rem];
  }
}
