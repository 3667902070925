.block-form-phone-wrapper {
  @apply bg-white fixed top-0 z-[9990] overflow-hidden w-screen h-screen flex justify-center text-center;
}

.block-form-phone-wrapper .block-left,
.block-form-phone-wrapper .block-right {
  @apply w-1/2 p-[2rem] flex flex-col justify-center items-center;
}

.block-form-phone-wrapper .block-left .banner-form-phone {
  @apply w-full h-full max-w-[33rem] max-h-[33rem] m-auto;
}

.block-form-phone-wrapper .block-right {
  @apply bg-[#FFEDE7];
}

.block-form-phone-wrapper .block-right .image-logo {
  @apply mb-[1rem];
}

.block-form-phone-wrapper .block-right .image-logo .logo-form-phone {
  @apply w-full h-full max-w-[11.25rem] max-h-[11.25rem];
}

.block-form-phone-wrapper .block-right .title {
  @apply text-[3rem] text-[#FD6B35] font-semibold;
}

.block-form-phone-wrapper .block-right .sub-title {
  @apply text-[1.5rem] text-[#FD6B35] font-normal mb-[1.5rem];
}

.block-form-phone-wrapper .block-right form {
  @apply w-full max-w-[25rem];
}

.block-form-phone-wrapper .block-right form .label-input-phone {
  @apply w-full px-[0.6rem];
}

.block-form-phone-wrapper .block-right form .label {
  @apply text-[0.875rem] font-medium;
}

.block-form-phone-wrapper .block-right form .input {
  @apply w-full rounded-[1rem] border-[#707070];
}

.block-form-phone-wrapper .block-right form .btn-form-phone {
  @apply text-[1.25rem] font-medium bg-[#FD6B35] rounded-full text-white mt-[2.5rem] w-full;
}

@media screen and (max-width: 1024.98px) {
  .block-form-phone-wrapper .block-left {
    @apply hidden;
  }
  .block-form-phone-wrapper .block-right {
    @apply w-full;
  }
}

@media screen and (max-width: 430.98px) {
  .block-form-phone-wrapper .block-right .title {
    @apply text-[1.875rem];
  }
  .block-form-phone-wrapper .block-right .sub-title {
    @apply text-[1.25rem];
  }
}

@media screen and (max-width: 399.98px) {
  .block-form-phone-wrapper .block-right .title {
    @apply text-[1.675rem];
  }
  .block-form-phone-wrapper .block-right .sub-title {
    @apply text-[1rem];
  }
}
