.block-login .block-login-form {
  @apply bg-white relative z-[9990] p-[2rem] overflow-hidden w-screen h-screen flex flex-col justify-center text-center;
}

.block-login .block-login-form {
  @apply bg-[#FFEDE7];
}

.block-login .block-login-form .image-logo {
  @apply flex justify-center mt-[1.2rem] mb-[1.2rem];
}

.block-login .block-login-form .image-logo .logo-login {
  @apply w-full h-full max-w-[11.25rem] max-h-[11.25rem];
}

.block-login .block-login-form .image-banner-content {
  @apply flex justify-center py-[1.3rem];
}

.block-login .block-login-form .image-banner-content .banner-login {
  @apply flex justify-center w-full h-full max-w-[33.063rem] max-h-[24.688rem];
}

.block-login-form .btn-login-group {
  @apply flex flex-col items-center;
}

.block-login .block-login-form .btn-login-group .btn-login {
  @apply text-[1.25rem] font-medium bg-[#FD6B35] rounded-full text-white my-[1rem] w-full h-[3.5rem] max-w-[23.875rem];
}

.block-login .block-login-form .btn-login-group .btn-login {
  @apply max-w-full;
}

.block-login {
  @apply bg-white fixed top-0 z-[9990] overflow-hidden w-screen h-screen flex justify-center text-center;
}

.block-login .image-banner-content,
.block-login .block-login-form {
  @apply w-1/2;
}

.block-login .image-banner-content {
  @apply m-auto;
}

.block-login .image-banner-content .banner-login {
  @apply w-full h-full max-w-[37.2rem] max-h-[24.8rem] m-auto;
}

.block-login .block-login-form .title {
  @apply text-[48px] text-[#FD6B35] font-semibold py-[0.7rem];
}

.block-login .block-login-form .sub-title {
  @apply text-[24px] text-[#FE914E] font-normal py-[0.3rem] mb-[2rem];
}

.block-login .block-login-form .image-banner-content {
  @apply hidden;
}

.block-login .block-login-form form .input-group-login {
  @apply max-w-[30rem] m-auto;
}

.block-login .block-login-form form .input-group-login .form-user-password {
  @apply flex items-center gap-2 rounded-[18px];
}

.block-login .block-login-form form .btn-login-group {
  @apply mx-auto max-w-[30rem];
}

.block-login .block-login-form form .btn-login-group .link-forgot-password {
  @apply text-[16px] text-[#FD6B35] font-semibold text-end mb-[1.563rem] ml-auto mr-5;
}

.block-login .block-login-form .no-user {
  @apply text-[16px] text-[#FD6B35] font-medium mt-[2rem];
}

.block-login .block-login-form .no-user .link-no-user {
  @apply text-[#303536];
}

@media screen and (max-width: 1024.98px) {
  .block-login .block-login-form {
    @apply bg-white;
  }

  .block-login {
    display: unset;
  }

  .block-login .image-banner-content,
  .block-login .block-login-form {
    @apply w-full;
  }

  .block-login .image-banner-content {
    @apply hidden m-0 mx-auto;
  }

  .block-login .block-login-form .image-banner-content {
    display: unset;
  }
}

@media screen and (max-width: 834.98px) {
  .block-login .block-login-form .image-banner-content .banner-login {
    @apply max-w-[24.2rem] max-h-[14.8rem];
  }
}

@media screen and (max-width: 768.98px) {
  .block-login .block-login-form .sub-title {
    @apply mb-[1rem];
  }
}

@media screen and (max-width: 620.98px) {
  .block-login .block-login-form .image-banner-content .banner-login {
    @apply max-h-[12.6rem] max-w-[21.06rem];
  }

  .block-login .block-login-form .title {
    @apply text-[40px];
  }

  .block-login .block-login-form .sub-title {
    @apply text-[20px];
  }

  .block-login .block-login-form form .no-user {
    @apply mt-[1rem];
  }
}

@media screen and (max-width: 430.98px) {
  .block-login .block-login-form .image-logo .logo-login {
    @apply max-w-[9.75rem] max-h-[9.375rem];
  }

  .block-login .block-login-form .title {
    @apply text-[35px] py-[0.3rem];
  }

  .block-login .block-login-form .sub-title {
    @apply text-[20px] py-[0.3rem];
  }

  .block-login .block-login-form .input-group-login {
    @apply my-2;
  }

  .block-login .block-login-form .input-group-login .input {
    @apply mb-[1.5rem];
  }

  .block-login .block-login-form .input-group-login .icon-login {
    @apply w-[1.125rem] h-[1.125rem] opacity-70;
  }

  .block-login .block-login-form .btn-login-group .link-forgot-password {
    @apply text-[14px];
  }
}

@media screen and (max-width: 375.98px) {
  .block-login .block-login-form .image-logo .logo-login {
    @apply max-w-[7.75rem] max-h-[7.375rem];
  }

  .block-login .block-login-form form .btn-login-group .link-forgot-password {
    @apply mb-[0.5rem];
  }

  .block-login .block-login-form .image-banner-content .banner-login {
    @apply max-w-[19.2rem] max-h-[9.8rem];
  }
}

@media screen and (max-width: 375.98px) and (max-height: 667.98px) {
  .block-login .block-login-form .image-banner-content {
    @apply hidden;
  }
}
