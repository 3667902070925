.block-register-wrapper {
  @apply bg-white fixed top-0 z-[9990] overflow-hidden overflow-y-auto w-screen h-screen flex justify-center text-center;
}

.block-register-wrapper .bg-form {
  @apply w-full h-auto absolute bottom-0;
}

.block-register-wrapper .block-register {
  @apply bg-[#FFEDE7] w-full max-w-[65rem] flex flex-col justify-center items-center z-[1];
}

.block-register-wrapper .block-register .image-logo {
  @apply mb-[1rem];
}

.block-register-wrapper .block-register .image-logo .logo-register {
  @apply w-full h-full max-w-[11.25rem] max-h-[11.25rem];
}

.block-register-wrapper .block-register .title {
  @apply text-[3rem] text-[#FD6B35] font-semibold mb-[1rem];
}

.block-register-wrapper .block-register form {
  @apply w-full py-[1.5rem] max-w-[57rem];
}

.block-register-wrapper .block-register form .group-form {
  @apply flex justify-between mb-[1.5rem];
}

.block-register-wrapper .block-register form .group-form .group-form-top-left,
.block-register-wrapper .block-register form .group-form .group-form-bottm-right {
  @apply w-1/2 px-[1.5rem];
}

.block-register-wrapper .block-register form .group-form .group-form-top-left .form-register,
.block-register-wrapper .block-register form .group-form .group-form-bottm-right .form-register {
  @apply flex items-center gap-2;
}

.block-register-wrapper .block-register form .group-form .group-form-top-left .form-register-label .label .label-text,
.block-register-wrapper .block-register form .group-form .group-form-bottm-right .form-register-label .label .label-text {
  @apply text-[0.875rem] font-medium;
}

.block-register-wrapper .block-register form .group-form .group-form-top-left .input,
.block-register-wrapper .block-register form .group-form .group-form-bottm-right .input {
  @apply rounded-[18px] border-[#707070] bg-white;
}

.block-register-wrapper .block-register form .btn-confirm,
.block-register-wrapper .block-register form .btn-cancel {
  @apply text-[1.25rem] font-medium bg-[#FD6B35] rounded-full border-[2px] border-[#FD6B35] text-white m-[1rem] w-full h-[3.5rem] max-w-[23.875rem];
}

.block-register-wrapper .block-register form .btn-cancel {
  @apply text-[#FD6B35] bg-white;
}

@media screen and (max-width: 1024.98px) {
  .block-register-wrapper .bg-form {
    @apply object-cover h-1/3;
  }
}

@media screen and (max-width: 834.98px) {
  .block-register-wrapper .block-register {
    @apply max-w-full;
  }
}

@media screen and (max-width: 430.98px) {
  .block-register-wrapper .block-register {
    @apply justify-start h-fit;
  }
  .block-register-wrapper .block-register .image-logo {
    @apply mb-[1rem];
  }
  .block-register-wrapper .block-register .title {
    @apply text-[1.875rem] mb-0;
  }
  .block-register-wrapper .block-register form .group-form {
    @apply flex-col;
  }
  .block-register-wrapper .block-register form .group-form .group-form-top-left,
.block-register-wrapper .block-register form .group-form .group-form-bottm-right {
    @apply w-full;
  }
  .block-register-wrapper .block-register form .btn-confirm,
.block-register-wrapper .block-register form .btn-cancel {
    @apply w-3/4 mx-0;
  }
}
