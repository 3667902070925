.tax-Invoice-information-edit-wrapper {
  @apply py-[2rem] overflow-x-hidden;
}

.title-tax-Invoice-information-edit {
  @apply text-[2.25rem] font-semibold text-[#1A1A1A] pb-6;
}

.block-form-tax-Invoice-information-edit {
  @apply relative px-[5rem] py-[1.5rem];
}

.form-control {
  @apply w-full pr-[3.5rem] mb-3;
}

.form-control.right {
  @apply pr-0 pl-[3.5rem];
}

.btn-group {
  @apply w-full h-full flex;
}

.btn-tax-Invoice-information-edit {
  @apply text-[1.25rem] font-medium text-white bg-[#FD6B35] w-full max-w-[245px] rounded-full mx-auto;
}

@media screen and (max-width: 1024.98px) {
  .tax-Invoice-information-edit-wrapper {
    @apply px-[2rem];
  }

  .block-form-tax-Invoice-information-edit {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .tax-Invoice-information-edit-wrapper {
    @apply px-[1.5rem] py-0;
  }

  .block-form-tax-Invoice-information-edit {
    @apply px-[0.5rem];
  }

  .title-tax-Invoice-information-edit {
    @apply text-[2rem] text-center;
  }

  .form-control {
    @apply px-0;
  }

  .form-control.right {
    @apply px-0;
  }
}
