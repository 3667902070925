.error-wrapper {
  @apply w-full h-full p-2;
}

.block-error {
  @apply relative flex flex-col items-center pt-[3rem] pb-[6rem] px-5;
}

.block-image {
  @apply px-5;
}

.error-title {
  @apply flex text-[2.5rem] font-semibold mb-3 text-center;
}

.error-sub-title {
  @apply flex text-[1.5rem] font-normal mb-5 text-center;
}

.btn-back-home {
  @apply text-[1.125rem] font-semibold text-white bg-[#FD6B35] w-full max-w-[245px] rounded-[14px];
}

.icon-arrow-right {
  @apply w-[1rem] h-auto;
}

/* @media screen and (max-width: 1024.98px) {
 
} */

@media screen and (max-width: 430.98px) {
  .error-title {
    @apply flex-col text-[2.25rem];
  }

  .error-sub-title {
    @apply flex-col;
  }

  .btn-back-home {
    @apply text-[1rem] max-w-[200px];
  }
}
