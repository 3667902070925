.block-phone-otp-wrapper {
  @apply bg-white fixed top-0 z-[9990] overflow-hidden w-screen h-screen flex justify-center text-center;
}

.block-phone-otp-wrapper .bg-form {
  @apply w-full h-auto absolute bottom-0;
}

.block-phone-otp-wrapper .block-form-otp {
  @apply bg-[#FFEDE7] w-full flex flex-col justify-center items-center z-[1];
}

.block-phone-otp-wrapper .block-form-otp .image-logo {
  @apply mb-[1rem];
}

.block-phone-otp-wrapper .block-form-otp .image-logo .logo-phone-otp {
  @apply w-full h-full max-w-[11.25rem] max-h-[11.25rem];
}

.block-phone-otp-wrapper .block-form-otp .title,
.block-phone-otp-wrapper .block-form-otp .phone-otp-send,
.block-phone-otp-wrapper .block-form-otp .reference-number {
  @apply mb-[0.625rem];
}

.block-phone-otp-wrapper .block-form-otp .title {
  @apply text-[3rem] text-[#FD6B35] font-semibold;
}

.block-phone-otp-wrapper .block-form-otp .phone-otp-send {
  @apply text-[1.5rem] text-[#303536] font-normal;
}

.block-phone-otp-wrapper .block-form-otp .reference-number {
  @apply text-[1.5rem] text-[#303536] font-semibold;
}

.block-phone-otp-wrapper .block-form-otp form {
  @apply w-full p-[1.5rem] max-w-[28.125rem];
}

.block-phone-otp-wrapper .block-form-otp form .label-input-phone {
  @apply flex justify-around;
}

.block-phone-otp-wrapper .block-form-otp form .label-input-phone .input-item {
  @apply w-[40px] h-[40px] bg-white border-0 border-[#303536] border-b-[2px] rounded-[0rem] px-[0.5rem] text-center text-[1.625rem] font-medium;
}

.block-phone-otp-wrapper .block-form-otp form .label-input-phone input::-webkit-outer-spin-button,
.block-phone-otp-wrapper .block-form-otp form .label-input-phone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.block-phone-otp-wrapper .block-form-otp form .label-input-phone input[type="number"] {
  -moz-appearance: textfield;
}

.block-phone-otp-wrapper .block-form-otp form .label-input-phone .input:focus,
.block-phone-otp-wrapper .block-form-otp form .label-input-phone .input:focus-within {
  outline-color: transparent;
}

.block-phone-otp-wrapper .block-form-otp .btn-new-otp {
  @apply text-[1.25rem] text-[#FD6B35] font-medium h-fit min-h-fit hover:bg-transparent p-[0.5rem] mt-[2.5rem];
}

.block-phone-otp-wrapper .block-form-otp .btn-new-otp.btn:disabled {
  @apply text-neutral;
}

.block-phone-otp-wrapper .block-form-otp .btn-send-otp {
  @apply text-[1.25rem] font-medium bg-[#FD6B35] rounded-full text-white mt-[2.5rem] w-full;
}

@media screen and (max-width: 1024.98px) {
  .block-phone-otp-wrapper .bg-form {
    @apply object-cover h-1/3;
  }
  .block-phone-otp-wrapper .block-form-otp {
    @apply w-full;
  }
}

@media screen and (max-width: 430.98px) {
  .block-phone-otp-wrapper .block-form-otp .title {
    @apply text-[1.875rem];
  }
  .block-phone-otp-wrapper .block-form-otp .phone-otp-send {
    @apply text-[1.25rem];
  }
  .block-phone-otp-wrapper .block-form-otp .reference-number {
    @apply text-[1.125rem];
  }
}
