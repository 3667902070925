.radio {
  @apply checked:bg-[#FD6B35];
}

.checkbox {
  @apply checked:bg-[#FD6B35];
}

.checkbox .check-list {
  @apply checked:border-transparent;
}

.collapse-arrow:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title .collapse-title-left .sub-title-name {
  @apply hidden;
}

.box-blank {
  @apply w-[1.5rem] h-[1.5rem] cursor-default;
}

.payment-wrapper {
  @apply py-[2rem] bg-[#F8F7FA];
}

.payment-wrapper .block-choose-payment .payment-title {
  @apply text-[1.75rem] font-semibold text-center mb-[1rem];
}

.payment-wrapper .block-choose-payment .payment-items .items-title {
  @apply text-[1.75rem] font-medium mb-[0.5rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse {
  @apply bg-white rounded-[14px] mb-[0.875rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title {
  @apply flex items-center justify-between;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-left,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-right {
  @apply flex;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-left .collapse-title-name,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-right .collapse-title-name {
  @apply flex items-center text-[1.5rem] text-[#303536] font-medium;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-left .collapse-title-name .icon-credit-orange,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-right .collapse-title-name .icon-credit-orange {
  @apply w-[1.5rem] h-[1.5rem] mr-[1rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-left .sub-title-name,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-right .sub-title-name {
  @apply flex items-center text-[1rem] ml-[2.3rem] mt-[0.3rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-left .sub-title-name .current-icon-card,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-right .sub-title-name .current-icon-card {
  @apply mr-[0.5rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-left .sub-title-name .current-card-name,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-right .sub-title-name .current-card-name {
  @apply mr-[1rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-left {
  @apply flex-col;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-right {
  @apply flex-col items-end z-10;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items {
  @apply flex justify-between items-center mx-[2.5rem] mb-2 py-[0.875rem] border-b-[1px] border-[#DEDEDE];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items:first-child {
  @apply border-t-[1px];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-left {
  @apply flex items-center;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-left .icon-plus-gray {
  @apply w-[1.5rem] h-[1.5rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-left .items-left-name {
  @apply ml-[0.5rem] text-[1.25rem] text-[#707070] font-normal;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-right {
  @apply flex items-center;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-right .card-number {
  @apply mr-[0.5rem] text-[1.25rem] text-[#707070] font-normal;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.add-card-items .btn-add-card-items {
  @apply w-fit h-fit min-h-fit text-[1.25rem] text-[#707070] font-normal bg-transparent border-transparent shadow-transparent p-0;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit {
  @apply border-none block py-0;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .input:focus,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .input:focus-within {
  @apply outline-none;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .input {
  @apply w-full border-0 border-b-[1px] rounded-none;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .input input {
  @apply w-full;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .input .text-label {
  @apply w-[7.8rem] text-[16px] font-normal;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .input.cvv .text-label {
  @apply w-[3.5rem];
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .add-card-terms-and-conditions {
  @apply flex items-center py-4;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .add-card-terms-and-conditions .checkbox {
  @apply mr-2;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .add-card-terms-and-conditions .terms-and-conditions {
  @apply text-[16px] font-normal;
}

.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .btn-add-card {
  @apply w-full max-w-[20rem] border-[1px] border-[#FD6B35] rounded-full bg-[#FD6B35] text-white text-[16px] m-auto;
}

.payment-wrapper .block-choose-payment .payment-items .qr-promptpay {
  @apply flex justify-between items-center p-[0.75rem] bg-white rounded-[14px] mb-[0.875rem];
}

.payment-wrapper .block-choose-payment .payment-items .qr-promptpay .qr-promptpay-left {
  @apply flex items-center;
}

.payment-wrapper .block-choose-payment .payment-items .qr-promptpay .qr-promptpay-left .icon-qr-promptpay {
  @apply w-[1.5rem] h-[1.5rem];
}

.payment-wrapper .block-choose-payment .payment-items .qr-promptpay .qr-promptpay-left .qr-promptpay-name {
  @apply ml-[1rem] text-[1.5rem] text-[#303536] font-medium;
}

.payment-wrapper .block-choose-payment .payment-items .qr-promptpay .qr-promptpay-right {
  @apply flex items-center;
}

@media screen and (max-width: 1024.98px) {
  .payment-wrapper .block-choose-payment {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 820.98px) {
  .payment-wrapper .block-form-add-card-credit .expire-cvv {
    @apply flex-col;
  }
}

@media screen and (max-width: 430.98px) {
  .payment-wrapper .block-choose-payment {
    @apply px-[1rem];
  }
  .payment-wrapper .block-choose-payment .payment-title {
    @apply text-[1.5rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .items-title {
    @apply text-[1.25rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-title .collapse-title-name {
    @apply text-[1.25rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items {
    @apply mx-0;
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-left .icon-master-card {
    @apply w-[1.25rem] h-[1.25rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-left .items-left-name {
    @apply text-[1rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items .content-items-right .card-number {
    @apply text-[1rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.add-card-items .btn-add-card-items .icon-plus-gray {
    @apply w-[1.25rem] h-[1.25rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.add-card-items .btn-add-card-items .btn-name {
    @apply text-[1rem];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .add-card-number .text-label {
    @apply text-[14px];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .expire-cvv .expire .text-label,
.payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .expire-cvv .cvv .text-label {
    @apply text-[14px];
  }
  .payment-wrapper .block-choose-payment .payment-items .collapse .collapse-content .content-items.block-form-add-card-credit .add-card-terms-and-conditions .terms-and-conditions {
    @apply text-[12px];
  }
  .payment-wrapper .block-choose-payment .payment-items .qr-promptpay .qr-promptpay-left .qr-promptpay-name {
    @apply text-[1.25rem];
  }
}
