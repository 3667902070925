.radio {
  @apply mr-2 checked:bg-[#FD6B35];
}

.checkbox {
  @apply checked:bg-[#FD6B35];
}

.recipient-details-wrapper {
  @apply py-[2rem] overflow-x-hidden;
}

.title {
  @apply text-[1.75rem] font-semibold text-[#1A1A1A];
}

.block-form-recipient-details {
  @apply relative px-[5rem] py-[1.5rem];
}

.form-contact,
.form-full-e-tax,
.form-e-tax {
  @apply grid grid-cols-2;
}

.form-control {
  @apply w-full pr-[3.5rem] mb-3;
}

.form-control .input,
.form-control .select {
  @apply w-full border-[#707070] rounded-[16px] text-[18px] text-black;
}

.form-control .input::placeholder,
.form-control .select::placeholder {
  @apply text-[#ADB5BD];
}

.form-control .label .label-text {
  @apply text-[1rem] text-black font-medium;
}

.form-control .label .required::after {
  @apply content-["_*"] text-[#D32F2F];
}

.form-e-tax-radio,
.form-same-address-above-checkbox {
  @apply flex py-4;
}

.form-e-tax-radio .label .label-text,
.form-same-address-above-checkbox .label .label-text {
  @apply text-[1rem] text-black font-medium;
}

.form-e-tax-radio .label {
  @apply max-w-fit pr-3;
}

.form-same-address-above-checkbox .label {
  @apply max-w-fit pr-3;
}

.form-same-address-above-checkbox .label .checkbox {
  @apply mr-2 [--chkbg:#FD6B35] [--chkfg:#FFFFFF];
}

.annotation {
  @apply text-[1rem] font-medium text-[#D32F2F];
}

@media screen and (max-width: 1024.98px) {
  .recipient-details-wrapper {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .recipient-details-wrapper {
    @apply px-[1.5rem] py-0;
  }

  .block-form-recipient-details {
    @apply px-[0.5rem] py-[1.5rem];
  }

  .form-contact,
  .form-full-e-tax,
  .form-e-tax {
    @apply block;
  }

  .form-control {
    @apply pr-0;
  }

  .form-control .input,
  .form-control .select {
    @apply text-[16px];
  }
}
