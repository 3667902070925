.block-profile-wrapper {
  @apply relative;
}

.block-profile-wrapper .bg-header {
  @apply absolute top-0 w-full h-auto;
}

.block-profile-wrapper .block-profile {
  @apply p-[2rem] z-[2] relative bg-transparent;
}

.block-profile-wrapper .block-profile .title-profile-page {
  @apply text-[1.625rem] text-white font-semibold mb-[1.25rem];
}

.block-profile-wrapper .block-profile .item-wrapper {
  @apply mb-[1.25rem] py-[1.125rem] px-[1.125rem] rounded-[14px] bg-white shadow-[0_2px_24px_0_rgba(0,0,0,0.1)];
}

.block-profile-wrapper .block-profile .profile-wrapper {
  @apply flex items-center justify-between;
}

.block-profile-wrapper .block-profile .profile-wrapper .profile {
  @apply flex items-center;
}

.block-profile-wrapper .block-profile .profile-wrapper .profile .block-avatar {
  @apply w-[6.063rem] h-[6.063rem];
}

.block-profile-wrapper .block-profile .profile-wrapper .profile .block-avatar .avatar {
  @apply w-full h-full rounded-full;
}

.block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email {
  @apply ml-[1rem];
}

.block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email .welcome {
  @apply text-[1.5rem] text-[#FD6B35] font-bold;
}

.block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email .welcome .name {
  @apply text-[2rem] text-[#404040] font-bold ml-3;
}

.block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email .email {
  @apply text-[1.125rem] text-[#757575] font-normal;
}

.block-profile-wrapper .block-profile .profile-wrapper .link-edit-profile {
  @apply w-[2rem] h-[2rem];
}

.block-profile-wrapper .block-profile .profile-wrapper .link-edit-profile .icon-edit {
  @apply w-full h-full;
}

.block-profile-wrapper .block-profile .items-link-page {
  @apply flex items-center justify-between border-[1px] border-transparent hover:border-[#FD6B35];
}

.block-profile-wrapper .block-profile .items-link-page .item-link-name {
  @apply flex items-center;
}

.block-profile-wrapper .block-profile .items-link-page .item-link-name .item-link-icon {
  @apply w-[1.5rem] h-[1.5rem];
}

.block-profile-wrapper .block-profile .items-link-page .item-link-name .item-link-title {
  @apply text-[1.25rem] text-[#303536] font-normal ml-3;
}

.block-profile-wrapper .block-profile .items-link-page .btn-item-link {
  @apply w-full h-auto max-w-[2rem] max-h-[2rem] p-[0.25rem];
}

.block-profile-wrapper .block-profile .items-link-page .btn-item-link .icon-next-orange {
  @apply w-full h-full;
}

.block-profile-wrapper .block-profile .social-media-wrapper .title-social-media {
  @apply text-[1rem] text-[#787878] font-normal mb-4;
}

.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media,
.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media-right {
  @apply flex items-center justify-between;
}

.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media {
  @apply mb-3;
}

.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media .social-media-left,
.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media .social-media-right {
  @apply flex;
}

.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media .social-media-left .icon-media {
  @apply w-full h-auto;
}

.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media .social-media-left .media-name {
  @apply text-[1.25rem] text-[#333333] font-normal ml-3;
}

.block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media .social-media-right .title-toggle {
  @apply text-[1.125rem] text-[#212529] font-normal ml-3;
}

@media screen and (max-width: 430.98px) {
  .block-profile-wrapper .bg-header {
    @apply h-[7rem];
  }
  .block-profile-wrapper .block-profile {
    @apply p-[1rem];
  }
  .block-profile-wrapper .block-profile .title-profile-page {
    @apply text-[1.5rem];
  }
  .block-profile-wrapper .block-profile .profile-wrapper .profile .block-avatar {
    @apply w-[3.625rem] h-[3.625rem];
  }
  .block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email {
    @apply ml-[0.5rem];
  }
  .block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email .welcome {
    @apply text-[0.875rem];
  }
  .block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email .welcome .name {
    @apply text-[1.25rem];
  }
  .block-profile-wrapper .block-profile .profile-wrapper .profile .block-name-email .email {
    @apply text-[1rem];
  }
  .block-profile-wrapper .block-profile .profile-wrapper .link-edit-profile {
    @apply w-[1.25rem] h-[1.25rem];
  }
  .block-profile-wrapper .block-profile .items-link-page .item-link-name .item-link-title {
    @apply text-[1rem] ml-[0.5rem];
  }
  .block-profile-wrapper .block-profile .social-media-wrapper .title-social-media {
    @apply text-[0.875rem] mb-3;
  }
  .block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media .social-media-left .media-name {
    @apply text-[1rem] ml-[0.5rem];
  }
  .block-profile-wrapper .block-profile .social-media-wrapper .block-social-media .social-media .social-media-right .title-toggle {
    @apply text-[1rem] ml-[0.5rem];
  }
}
