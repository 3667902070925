.footer-desktop-wrapper {
  @apply w-full h-full bg-cover bg-no-repeat;
  background-image: url("../../../public/assets/images/bg-footer.png");
}

.footer-desktop-wrapper .block-footer-desktop {
  @apply py-[3rem];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top {
  @apply flex justify-around;
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .block-image {
  @apply w-full h-full max-w-[11rem] max-h-[11rem];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .block-image .logo-footer {
  @apply w-full h-full;
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items {
  @apply flex flex-col px-[1rem];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .items-text {
  @apply text-[1.125rem] font-medium text-white;
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .items-text.text-bold {
  @apply text-[1.25rem] font-bold;
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .link:hover {
  @apply decoration-[#FFFFFF];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .link.link-phone, .footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .link.link-line {
  @apply flex flex-row items-center mb-[0.5rem];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .link.link-phone .link-icon, .footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .link.link-line .link-icon {
  @apply w-[1.125rem] h-[1.125rem];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .link.link-phone .link-text, .footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .link.link-line .link-text {
  @apply text-[1rem] font-medium text-white ml-[0.625rem];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-bottom {
  @apply flex justify-between items-center border-t-[1px] border-[#EAECF0] pt-[2rem];
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-bottom .desktop-bottom-left,
.footer-desktop-wrapper .block-footer-desktop .footer-desktop-bottom .desktop-bottom-right {
  @apply text-[1rem] font-medium text-white;
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-bottom .desktop-bottom-right {
  @apply flex justify-between items-center;
}

.footer-desktop-wrapper .block-footer-desktop .footer-desktop-bottom .desktop-bottom-right .bottom-text-items {
  @apply ml-[1rem];
}

@media screen and (max-width: 1280.98px) {
  .footer-desktop-wrapper .block-footer-desktop {
    @apply py-[3rem] px-[2rem];
  }
}

@media screen and (max-width: 834.98px) {
  .footer-desktop-wrapper .block-footer-desktop {
    @apply py-[2rem];
  }
  .footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items {
    @apply min-w-[16rem];
  }
  .footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .items-text {
    @apply text-[0.875rem];
  }
  .footer-desktop-wrapper .block-footer-desktop .footer-desktop-top .desktop-top-items .items-text.text-bold {
    @apply text-[1rem];
  }
  .footer-desktop-wrapper .block-footer-desktop .footer-desktop-bottom {
    @apply flex-col pt-[1rem];
  }
  .footer-desktop-wrapper .block-footer-desktop .footer-desktop-bottom .desktop-bottom-left {
    @apply mb-4;
  }
}

@media screen and (max-width: 770.98px) {
  .footer-desktop-wrapper {
    @apply hidden;
  }
}
