.block-view-detail-wrapper {
  @apply py-[1rem];
}

.block-view-detail-wrapper .title-view-detail-page {
  @apply py-[1rem];
}

.block-view-detail-wrapper .title-view-detail-page .title {
  @apply text-[2rem] font-semibold;
}

.block-view-detail-wrapper .block-product-details {
  @apply mb-[1rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details {
  @apply flex flex-row w-full;
}

.block-view-detail-wrapper .block-product-details .card-product-details .block-image-product {
  @apply w-full max-w-[27.063rem] max-h-[30.5rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body {
  @apply py-[1rem] max-w-[52rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .card-title {
  @apply text-[2rem] font-semibold mb-[1rem] leading-tight;
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .list-product {
  @apply h-full;
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .list-product .list-product-title {
  @apply text-[1.5rem] font-semibold mb-[0.5rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .list-product .form-list-product .label {
  @apply justify-start;
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .list-product .form-list-product .label .radio {
  @apply mr-2 checked:bg-[#FD6B35];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .list-product .form-list-product .label .label-text {
  @apply text-[1.5rem] font-normal;
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products-title {
  @apply text-[1rem] font-medium mb-[0.5rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products {
  @apply max-w-[12rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products .input {
  @apply flex items-center p-0 h-[2rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products .input .btn {
  @apply rounded-none p-0 w-[2rem] h-[2rem] min-h-[2rem];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products .input .decrease-number {
  @apply text-[1rem] border-[#303536] rounded-l-lg;
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products .input .increase-number {
  @apply text-[1rem] border-[#303536] rounded-r-lg;
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products .input input[type=number]::-webkit-inner-spin-button,
.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products .input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .block-number-products .number-products .input .input-number-products {
  @apply w-full text-center rounded-none border-[1px] border-[#303536];
}

.block-view-detail-wrapper .block-product-details .card-product-details .card-body .btn-add-to-cart .btn {
  @apply text-[1.25rem] text-white font-medium bg-[#FD6B35] w-full h-full max-w-[20rem] min-h-[2.5rem] rounded-full mt-2;
}

.block-view-detail-wrapper .block-notation {
  @apply mb-[2rem];
}

.block-view-detail-wrapper .block-notation .form-notation .label .label-text {
  @apply text-[1.5rem] font-semibold mb-[1rem];
}

.block-view-detail-wrapper .block-notation .form-notation .textarea {
  @apply text-[1rem] font-semibold;
}

.block-view-detail-wrapper .block-key-highlight {
  @apply mb-[2rem];
}

.block-view-detail-wrapper .block-key-highlight .key-highlight-title {
  @apply text-[2rem] font-semibold mb-2;
}

.block-view-detail-wrapper .block-key-highlight .key-highlight-list {
  @apply list-disc;
}

.block-view-detail-wrapper .block-key-highlight .key-highlight-list li {
  @apply ml-[2rem] text-[1.5rem] font-normal;
}

.block-view-detail-wrapper .btn-go-back {
  @apply py-[2rem] text-center;
}

.block-view-detail-wrapper .btn-go-back .btn {
  @apply w-full h-full max-w-[23rem] text-[1.25rem] font-medium text-[#FD6B35] hover:text-white bg-white hover:bg-[#FD6B35] border-[2px] border-[#FD6B35] rounded-full;
}

@media screen and (max-width: 1024.98px) {
  .block-view-detail-wrapper {
    @apply px-[2rem];
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .block-image-product {
    @apply max-w-[21rem];
  }
}

@media screen and (max-width: 834.98px) {
  .block-view-detail-wrapper {
    @apply px-[2rem];
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .block-image-product {
    @apply max-w-[17rem];
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .card-body .card-title {
    @apply text-[1.5rem] mb-[0.5rem];
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .card-body .list-product .list-product-title {
    @apply text-[1.25rem];
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .card-body .list-product .form-list-product .label .label-text {
    @apply text-[1.25rem];
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .card-body .btn-add-to-cart .btn {
    @apply text-[1rem];
  }
  .block-view-detail-wrapper .block-key-highlight .key-highlight-title {
    @apply text-[1.75rem];
  }
  .block-view-detail-wrapper .block-key-highlight .key-highlight-list li {
    @apply text-[1.25rem];
  }
}

@media screen and (max-width: 430.98px) {
  .block-view-detail-wrapper {
    @apply px-[1rem];
  }
  .block-view-detail-wrapper .title-view-detail-page {
    @apply py-[0.5rem];
  }
  .block-view-detail-wrapper .title-view-detail-page .title {
    @apply text-[1.25rem] mb-[1.5rem];
  }
  .block-view-detail-wrapper .block-product-details .card-product-details {
    @apply flex-col;
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .block-image-product {
    @apply mx-auto p-2 mb-2;
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .card-body {
    @apply py-5 px-2;
  }
  .block-view-detail-wrapper .block-product-details .card-product-details .card-body .btn-add-to-cart .btn {
    @apply max-w-full mt-[2rem];
  }
  .block-view-detail-wrapper .block-notation {
    @apply mb-[1rem];
  }
  .block-view-detail-wrapper .block-notation .form-notation .label .label-text {
    @apply text-[1.25rem] mb-[0.5rem];
  }
  .block-view-detail-wrapper .block-key-highlight {
    @apply mb-[1rem];
  }
  .block-view-detail-wrapper .block-key-highlight .key-highlight-title {
    @apply text-[1.5rem];
  }
}
