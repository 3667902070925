.block-header-wrapper {
  @apply fixed w-full top-0 z-[99999] bg-white py-[1.8rem] px-[4rem] shadow-[0_2px_24px_0_rgba(0,0,0,0.1)];
}

.block-header-wrapper .block-navbar-menu {
  @apply flex items-center justify-between;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-left {
  @apply flex items-center justify-between space-x-4;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-left .block-logo-navbar {
  @apply w-full h-auto max-w-[12rem] mr-[0.5rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-left .block-logo-navbar .logo-navbar {
  @apply w-full h-full;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-left .menu-left-list {
  @apply flex space-x-4 text-[1.25rem] font-medium;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right {
  @apply flex items-center justify-between space-x-4;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list {
  @apply flex items-center space-x-4;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .input-search {
  @apply flex items-center gap-2 w-[20rem] h-[2.5rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu {
  @apply w-[32px] h-[32px] min-h-0 flex;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .flex-none .indicator .icon,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .btn-menu-bell .indicator .icon {
  @apply w-full h-full;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .badge {
  @apply border-[#FFE6D7] bg-[#FFE6D7] text-[#FD6B35] font-medium;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card {
  @apply w-[32rem] bg-[#F8F7FA];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .title {
  @apply text-[1.25rem] font-medium text-black;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart {
  @apply w-full h-full rounded-md py-2 max-h-[30rem] overflow-y-auto;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items {
  @apply overflow-hidden relative w-full h-fit bg-white rounded-md mb-3 flex;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item {
  @apply w-full h-full flex pr-[2.25rem] py-2;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-image {
  @apply px-[0.5rem] pt-[0.5rem] pb-1 drop-shadow-lg rounded-lg;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-image .item-image {
  @apply w-[4.25rem] min-w-[4.25rem] h-[4.25rem] min-h-[4.25rem] drop-shadow-lg rounded-lg;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name {
  @apply w-full px-2 pt-[0.5rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .card-name {
  @apply text-[1rem] text-[#1A1A1A] font-medium mb-3 px-2 border-l-[3px] border-[#FD6B35];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products {
  @apply mb-[0.75rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products {
  @apply max-w-[8rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products .input {
  @apply flex items-center p-0 h-[2rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products .input .btn {
  @apply rounded-none p-0 w-[2rem] h-[2rem] min-h-[2rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products .input .decrease-number {
  @apply text-[1rem] border-[#ADB5BD] bg-white rounded-l-lg;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products .input .increase-number {
  @apply text-[1rem] border-[#ADB5BD] bg-white rounded-r-lg;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products .input input[type="number"]::-webkit-inner-spin-button,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products .input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .block-card-number-products .card-number-products .input .input-number-products {
  @apply w-full pr-3 text-center rounded-none border-[1px] border-[#ADB5BD];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .card-items .card-item .card-item-name .product-price-unit {
  @apply text-[1.125rem] text-[#FE914E] font-semibold;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .cart .btn-delete {
  @apply absolute right-0 top-1/2 w-fit h-fit p-0 mr-2 m-0 min-h-0 bg-transparent bg-white rounded-md mb-2 ;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .total {
  @apply text-[1.25rem] font-medium text-black flex justify-between mb-2;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .btn-group {
    @apply w-full h-full flex;
  }

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .btn-group .btn-view {
  @apply  w-full h-full text-[1.125rem] font-medium text-white bg-[#FD6B35] rounded-lg hover:bg-[#333333] hover:border-[#333333];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab {
  @apply w-[15rem] h-[2.5rem] text-[1.125rem] text-[#FD6B35] font-medium;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab.tab-active:not(.tab-disabled):not([disabled]), .block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab:is(input:checked) {
  @apply border-[#FD6B35];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content {
  @apply max-h-[30rem] overflow-y-auto px-2 py-5;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-message,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-transportation {
  @apply flex flex-row justify-between items-center px-[0.75rem] py-[1.125rem] rounded-xl mb-[1rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-message .card-message-icon .icon.icon-status,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-transportation .card-message-icon .icon.icon-status {
  @apply min-w-[2.625rem] min-h-[2.625rem] rounded-full;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-message .card-message-content,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-transportation .card-message-content {
  @apply px-[1rem] mx-[0.5rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-message .card-message-content .message-content-title,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-transportation .card-message-content .message-content-title {
  @apply text-[1.125rem] font-semibold;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-message .card-message-content .message-content-date-time,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-transportation .card-message-content .message-content-date-time {
  @apply text-[0.75rem] font-normal my-[0.5rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-message .card-message-btn .dropdown .btn,
.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-transportation .card-message-btn .dropdown .btn {
  @apply p-0 bg-transparent border-transparent shadow-none;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .btn-menu .card .card-body .tabs .tab-content .card-transportation {
  @apply justify-start;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile {
  @apply hidden;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu {
  @apply relative w-8/12 min-h-full text-base-content bg-white p-[2.5rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu .btn-toggle-menu-mobile {
  @apply absolute top-0 left-0 translate-x-[-20%] translate-y-[50%] w-[3.75rem] h-[3.75rem];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li {
  @apply mb-[1.5rem] px-3 py-1;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile {
  @apply flex items-center pb-[1.5rem] pr-[1.5rem] border-b-[1px] border-[#FD6B35] rounded-none;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-avatar .avatar {
  @apply w-[4.375rem] h-auto border-[3px] border-[#ADB5BD] rounded-full;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-name-email {
  @apply ml-5;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-name-email .name {
  @apply text-[30px] text-[#404040] font-bold mb-3;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-name-email .email {
  @apply text-[24px] text-[#404040] font-normal;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .block-list-menu {
  @apply flex items-center;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .block-list-menu .icon-menu {
  @apply w-[40px] h-[40px];
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .block-list-menu .icon-menu .icon-item {
  @apply w-full h-full;
}

.block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .block-list-menu .name-menu {
  @apply text-[28px] font-medium ml-5;
}

@media screen and (max-width: 1200.98px) {
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .input-search {
    @apply w-[13rem];
  }
}

@media screen and (max-width: 1024.98px) {
  .block-header-wrapper {
    @apply px-[2rem];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-left .menu-left-list {
    @apply text-[1.125rem];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list .input-search {
    @apply w-[13rem];
  }
}

@media screen and (max-width: 834.98px) {
  .block-header-wrapper .block-navbar-menu .navbar-menu-left .menu-left-list {
    @apply hidden;
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .menu-right-list {
    @apply hidden;
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile {
    display: unset;
  }
}

@media screen and (max-width: 600.98px) {
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu {
    @apply w-11/12;
  }
}

@media screen and (max-width: 430.98px) {
  .block-header-wrapper {
    @apply py-[1rem];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu {
    @apply px-[1.3rem];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu .btn-toggle-menu-mobile {
    @apply w-[2.5rem] h-[2.5rem];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li {
    @apply mb-[1rem];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-avatar .avatar {
    @apply w-[3.625rem] h-[3.625rem];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-name-email {
    @apply ml-3;
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-name-email .name {
    @apply text-[20px];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .profile .block-name-email .email {
    @apply text-[14px];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .block-list-menu .icon-menu {
    @apply w-[20px] h-[20px];
  }
  .block-header-wrapper .block-navbar-menu .navbar-menu-right .btn-toggle-menu-mobile .drawer-side .menu li .block-list-menu .name-menu {
    @apply text-[18px] ml-3;
  }
}
