.container-items {
  @apply lg:container lg:mx-auto;
}

.payment-detail-wrapper {
  @apply py-[2rem] bg-[#F8F7FA];
}

.payment-detail-wrapper .block-payment-detail {
  @apply py-[1.5rem] mb-4 bg-white;
}

.payment-detail-wrapper .block-payment-detail .block-payment-amount .payment-amount-title {
  @apply flex justify-around items-center mb-3;
}

.payment-detail-wrapper .block-payment-detail .block-payment-amount .payment-amount-title .title-name {
  @apply w-full max-w-[9.5rem] mx-[0.875rem] text-[1.75rem] font-semibold text-center;
}

.payment-detail-wrapper .block-payment-detail .block-payment-amount .payment-amount-title .line {
  @apply w-full h-[1px] bg-[#303536];
}

.payment-detail-wrapper .block-payment-detail .block-payment-amount .total-payment {
  @apply flex justify-between;
}

.payment-detail-wrapper .block-payment-detail .block-payment-amount .total-payment .total-payment-title {
  @apply text-[2rem] font-semibold;
}

.payment-detail-wrapper .block-payment-detail .block-payment-amount .total-payment .total-payment-number {
  @apply text-[2rem] font-bold;
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title {
  @apply flex justify-around items-center mb-3;
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .address-title-name {
  @apply w-full max-w-[15.688rem] mx-[0.875rem] text-[1.75rem] font-semibold text-center;
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .address-title-name {
  @apply max-w-[4.5rem];
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .line,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .line,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .line,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .line {
  @apply w-full h-[1px] bg-[#303536];
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details {
  @apply flex items-center mb-4;
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .address-image .logo-bank {
  @apply w-full h-full max-w-[10.25rem] max-h-[10.25rem];
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-details,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-details,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-details,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-details {
  @apply ml-[2rem];
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-details .bank-details-title {
  @apply text-[1.5rem] font-normal;
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-details .bank-details-content {
  @apply text-[1.75rem] font-semibold;
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .block-btn-edit-address,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .block-btn-edit-address {
  @apply flex justify-end;
}

.payment-detail-wrapper .block-payment-detail .block-payment-slip .block-btn-edit-address .btn-edit,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .block-btn-edit-address .btn-edit {
  @apply text-[1.25rem] text-[#FE914E] font-normal bg-transparent border-transparent shadow-none hover:scale-[1.1];
}

.payment-detail-wrapper .block-payment-detail .block-btn {
  @apply mb-[1rem];
}

.payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number,
.payment-detail-wrapper .block-payment-detail .block-btn .attach-slip {
  @apply flex justify-center;
}

.payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number {
  @apply mb-4;
}

.payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-copy-account-number,
.payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-admin {
  @apply w-full max-w-[15rem] text-white bg-[#FE914E] rounded-full text-[1.25rem] font-medium mx-[0.5rem];
}

.payment-detail-wrapper .block-payment-detail .block-btn .attach-slip .btn-attach-slip {
  @apply w-full max-w-[24rem] text-white bg-[#FD6B35] rounded-full text-[1.25rem] font-medium;
}

.payment-detail-wrapper .block-payment-detail .block-btn .attach-slip .image-slip {
  @apply w-full h-full max-w-[20rem] max-h-[24rem] my-[0.5rem] object-contain;
}

@media screen and (max-width: 1024.98px) {
  .payment-detail-wrapper .block-payment-detail {
    @apply px-[2rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-amount .payment-amount-title .title-name {
    @apply text-[1.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-amount .total-payment .total-payment-title {
    @apply text-[1.75rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-amount .total-payment .total-payment-number {
    @apply text-[1.75rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .address-title-name {
    @apply max-w-[15.688rem] text-[1.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .address-title-name {
    @apply max-w-[3.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .address-image .logo-bank {
    @apply max-w-[10rem] max-h-[10rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-details .bank-details-title {
    @apply text-[1.25rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-details .bank-details-content {
    @apply text-[1.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .block-btn-edit-address .btn-edit,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .block-btn-edit-address .btn-edit {
    @apply text-[1rem];
  }
  .payment-detail-wrapper .block-btn {
    @apply mb-[1rem];
  }
  .payment-detail-wrapper .block-btn .admin-account-number,
.payment-detail-wrapper .block-btn .attach-slip {
    @apply flex justify-center;
  }
  .payment-detail-wrapper .block-btn .admin-account-number {
    @apply mb-4;
  }
  .payment-detail-wrapper .block-btn .admin-account-number .btn-copy-account-number,
.payment-detail-wrapper .block-btn .admin-account-number .btn-admin {
    @apply max-w-[14rem];
  }
  .payment-detail-wrapper .block-btn .attach-slip .btn-attach-slip {
    @apply max-w-[23rem];
  }
}

@media screen and (max-width: 430.98px) {
  .payment-detail-wrapper .block-payment-detail {
    @apply px-[1rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-amount .payment-amount-title .title-name {
    @apply text-[1.25rem] mx-[0.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-amount .payment-amount-title .line {
    @apply w-11/12;
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-amount .total-payment .total-payment-title {
    @apply text-[1.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-amount .total-payment .total-payment-number {
    @apply text-[1.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .address-title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .title-name,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .address-title-name {
    @apply text-[1.25rem] mx-[0.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .line,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-title .line {
    @apply w-5/12;
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-title .line,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-title .line {
    @apply w-full;
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .address-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-image .logo-bank,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .address-image .logo-bank {
    @apply max-w-[5.75rem] max-h-[5.75rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-details .bank-details-title,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-details .bank-details-title {
    @apply text-[1rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-address-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-details .bank-details .bank-details-content,
.payment-detail-wrapper .block-payment-detail .block-payment-slip-address .payment-slip-address-details .bank-details .bank-details-content {
    @apply text-[1.25rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number {
    @apply justify-between;
  }
  .payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-copy-account-number,
.payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-admin {
    @apply text-[0.875rem] max-w-[11.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-copy-account-number .icon,
.payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-admin .icon {
    @apply w-[1.5rem] h-[1.5rem];
  }
  .payment-detail-wrapper .block-payment-detail .block-btn .attach-slip .btn-attach-slip {
    @apply text-[0.875rem] max-w-full;
  }
}

@media screen and (max-width: 390.98px) {
  .payment-detail-wrapper .block-payment-detail .block-payment-slip .payment-slip-title .line {
    @apply w-4/12;
  }
  .payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-copy-account-number,
.payment-detail-wrapper .block-payment-detail .block-btn .admin-account-number .btn-admin {
    @apply max-w-[10.25rem];
  }
}
