.not-found-wrapper {
  @apply w-full h-full p-2;
}

.block-not-found {
  @apply relative flex flex-col items-center pt-[3rem] pb-[6rem] px-5;
}

.block-not-found .block-image {
  @apply px-5 py-3;
}

.block-not-found .error-title {
  @apply flex text-[2.25rem] font-semibold py-5 text-center;
}

.block-not-found .btn-back-home {
  @apply text-[1.125rem] font-semibold text-white bg-[#FD6B35] w-full max-w-[245px] rounded-[14px] my-4;
}

.block-not-found .btn-back-home .icon-arrow-right {
  @apply w-[1rem] h-auto;
}

/* @media screen and (max-width: 1024.98px) {
 
} */

@media screen and (max-width: 430.98px) {
  .block-not-found .error-title {
    @apply flex-col text-[1.5rem] py-2;
  }

  .block-not-found .btn-back-home {
    @apply text-[1rem] max-w-[200px];
  }
}
