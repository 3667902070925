.product-list-cart-wrapper {
  @apply py-[2rem];
}

.product-list-cart-wrapper .block-product-items-list {
  @apply mb-[1rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item {
  @apply flex flex-col;
}

.product-list-cart-wrapper .block-product-items-list .product-item .item-title {
  @apply flex items-center mb-[1rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .item-title .title-checkbox {
  @apply border-[#FD6B35] [--chkbg:#FD6B35] [--chkfg:#FFFFFF];
}

.product-list-cart-wrapper .block-product-items-list .product-item .item-title .title-name {
  @apply ml-2 text-[1.875rem] text-[#212529] font-medium;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list {
  @apply p-3 border-[1px] rounded-md;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail {
  @apply flex;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-image {
  @apply w-full h-full max-w-[6.313rem] max-h-[6.313rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content {
  @apply w-full px-2 mb-[1.25rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .content-name {
  @apply text-[1.25rem] text-[#1A1A1A] font-medium mb-3 px-2 border-l-[3px] border-[#FD6B35];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products {
  @apply mb-[0.75rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products {
  @apply max-w-[8rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products .input {
  @apply flex items-center p-0 h-[2rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products .input .btn {
  @apply rounded-none p-0 w-[2rem] h-[2rem] min-h-[2rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products .input .decrease-number {
  @apply text-[1rem] border-[#ADB5BD] bg-white rounded-l-lg;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products .input .increase-number {
  @apply text-[1rem] border-[#ADB5BD] bg-white rounded-r-lg;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products .input input[type="number"]::-webkit-inner-spin-button,
.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products .input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .block-number-products .number-products .input .input-number-products {
  @apply w-full pr-3 text-center rounded-none border-[1px] border-[#ADB5BD];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .product-price-unit {
  @apply text-[1.125rem] text-[#FE914E] font-semibold;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-btn {
  @apply flex flex-col self-center;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-btn .btn {
  @apply w-[2.5rem] h-[2.5rem] max-h-[2.5rem] min-h-[2.5rem] p-[0.25rem] bg-white hover:bg-gray-100 border-[0.5px] border-[#ADB5BD] rounded-md;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-btn .btn.btn-icon-edit {
  @apply mb-[1rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-btn .btn .icon {
  @apply w-[1.5rem] h-[1.5rem];
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-total {
  @apply flex flex-row justify-between border-t-2 border-[#F2F2F2] pt-2;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-total .total-name,
.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-total .total-price {
  @apply text-[2rem] font-semibold;
}

.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-total .total-price {
  @apply text-[#FD6B35];
}

.product-list-cart-wrapper .block-btn-check-out {
  @apply text-center;
}

.product-list-cart-wrapper .block-btn-check-out .btn.btn-check-out {
  @apply w-full max-w-[21.5rem] text-[1.25rem] text-white font-medium rounded-full bg-[#FD6B35] border-[#FD6B35];
}

@media screen and (max-width: 1024.98px) {
  .product-list-cart-wrapper {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .product-list-cart-wrapper {
    @apply px-[1rem];
  }
  .product-list-cart-wrapper .block-product-items-list .product-item .item-title .title-name {
    @apply text-[1.25rem];
  }
  .product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .detail-content .content-name {
    @apply text-[1rem];
  }
  .product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .items-list-btn .btn {
    @apply h-[2rem] max-h-[2rem] min-h-[2rem];
  }
  .product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-list-detail .items-list-btn .btn .icon {
    @apply w-[1.25rem] h-[1.25rem];
  }
  .product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-total .total-name,
.product-list-cart-wrapper .block-product-items-list .product-item .items-list .items-total .total-price {
    @apply text-[1rem];
  }
}
