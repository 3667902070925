.contact-wrapper {
  @apply py-[2rem] overflow-x-hidden;

  .block-about-me,
  .block-address,
  .block-contact {
    @apply mb-2 p-3;
  }

  .block-about-me .title,
  .block-address .title,
  .block-contact .title {
    @apply text-[2rem] text-[#FD6B35] font-bold;
  }

  .block-about-me .details,
  .block-address .details,
  .block-contact .details {
    @apply text-[1.5rem] text-[#00000] font-normal max-w-[700px];
  }

  .block-contact .contact-list .list-items {
    @apply flex mb-2 text-[1.5rem] text-[#000000] font-normal;
  }

  .block-contact .contact-list .list-items .icon {
    @apply w-[2rem] h-[2rem] mr-3;
  }

  .google-Map-wrapper {
    @apply px-5 w-full h-[620px];
  }
}

@media screen and (max-width: 1024.98px) {
  .contact-wrapper {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .contact-wrapper {
    @apply px-[1.5rem] py-0;

    .block-about-me .title,
    .block-address .title,
    .block-contact .title {
      @apply text-[1.25rem];
    }

    .block-about-me .details,
    .block-address .details,
    .block-contact .details {
      @apply text-[1rem];
    }

    .block-contact .contact-list .list-items {
      @apply text-[1rem];
    }

    .block-contact .contact-list .list-items .icon {
      @apply w-[1.25rem] h-[1.25rem];
    }

    .google-Map-wrapper {
      @apply px-0 w-full h-[320px];
    }
  }
}
