.footer-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* height: 56px; */
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  font-weight: bold;
  background-color: #fff;
}

.footer-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  /* padding: 10px; */
  background: none;
  border: none;
  cursor: pointer;
}

.footer-button.active {
  color: #fd6b35;
}

.footer-button.active::after {
  content: "";
  display: block;
  width: 80%;
  height: 2px;
  background-color: #fd6b35;
}

.icon {
  width: 28px;
  height: 28px;
}
