.order-detail-wrapper {
  @apply py-[2rem] overflow-x-hidden;

  .block-order-detail .title {
    @apply pb-[1rem] text-[1.75rem] font-medium;
  }

  .block-steps {
    @apply text-center py-[1rem];
  }

  .block-steps .steps .step {
    @apply min-w-[10rem];
  }

  .block-steps .steps ::after {
    @apply bg-[#D1D5DB] text-[#9CA3AF];
  }

  .block-steps .steps ::before {
    @apply bg-[#D1D5DB];
  }

  .block-steps .steps .step-primary + .step-primary:before,
  .steps .step-primary:after {
    @apply bg-[#FD6B35] text-white;
  }

  .block-recipient-details,
  .block-ordered-items {
    @apply mb-[1rem];
  }

  .block-recipient-details .recipient-title,
  .block-ordered-items .ordered-items-title {
    @apply w-full flex justify-center items-center mt-[1rem] py-[1rem] mx-[1.5rem];
  }

  .block-recipient-details .recipient-title .line,
  .block-ordered-items .ordered-items-title .line {
    @apply border-t-[1px] border-[#303536] flex-grow;
  }

  .block-recipient-details .recipient-title .text,
  .block-ordered-items .ordered-items-title .text {
    @apply px-[1.25rem] whitespace-nowrap text-[1.75rem] font-semibold;
  }

  .block-recipient-details .recipient-details,
  .block-ordered-items .ordered-items {
    @apply flex justify-between text-[1.5rem] font-semibold mb-[0.5rem];
  }

  .block-total-price {
    @apply py-[1rem];
  }

  .block-total-price .items {
    @apply flex justify-between text-[2rem] font-semibold my-[1rem];
  }

  .block-btn {
    @apply flex justify-center py-[1rem];
  }

  .block-btn .btn-back {
    @apply w-full h-auto rounded-full max-w-[23rem] font-medium text-[1.25rem] text-[#FD6B35] bg-white border-[#FD6B35];
  }
}

@media screen and (max-width: 1024.98px) {
  .order-detail-wrapper {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .order-detail-wrapper {
    @apply px-[1.5rem] py-0;

    .block-order-detail .title {
      @apply text-[1.25rem] text-center;
    }

    .block-steps {
      @apply py-[0.5rem];
    }

    .block-steps .steps .step {
      @apply min-w-[6rem];
    }

    .block-recipient-details .recipient-title,
    .block-ordered-items .ordered-items-title {
      @apply mt-[1rem] py-[1rem] mx-0;
    }

    .block-recipient-details .recipient-title .text,
    .block-ordered-items .ordered-items-title .text {
      @apply px-[1rem] text-[1.25rem];
    }

    .block-recipient-details .recipient-details,
    .block-ordered-items .ordered-items {
      @apply text-[1.25rem];
    }
  }
}
