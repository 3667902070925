.terms-conditions-wrapper {
  @apply py-[2rem] overflow-x-hidden;
}

.title-terms-conditions {
  @apply text-[2.25rem] font-semibold text-[#1A1A1A];
}

.terms-conditions-content {
  @apply relative;
}

.terms-conditions-content .policy-content-title {
  @apply text-[1.5rem] font-semibold text-black mb-5;
}

.terms-conditions-content .policy-content-details {
  @apply text-[1.25rem] font-normal text-black mb-5;
}

.terms-conditions-content ul {
  @apply mb-5;
}

/* .terms-conditions-content ul li {
  @apply text-[1.25rem];
} */

.terms-conditions-content ul li p {
  @apply text-[1.25rem] font-normal;
}

.terms-conditions-content ul li p span {
  @apply text-[1.25rem] font-semibold;
}

@media screen and (max-width: 1024.98px) {
  .terms-conditions-wrapper {
    @apply px-[2rem];
  }
}

@media screen and (max-width: 430.98px) {
  .terms-conditions-wrapper {
    @apply px-[1.5rem] py-0;
  }

  .title-terms-conditions {
    @apply text-[2rem] text-center;
  }

  .terms-conditions-content .policy-content-title {
    @apply text-[1.25rem] mb-4;
  }

  .terms-conditions-content .policy-content-details {
    @apply text-[1rem] mb-4;
  }

  .terms-conditions-content ul {
    @apply mb-4;
  }

  .terms-conditions-content ul li p {
    @apply text-[1rem];
  }

  .terms-conditions-content ul li p span {
    @apply text-[1rem];
  }
}
