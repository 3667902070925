.block-reset-password-wrapper {
  @apply bg-white fixed top-0 z-[9990] overflow-hidden w-screen h-screen flex justify-center text-center;
}

.block-reset-password-wrapper .bg-form {
  @apply w-full h-auto absolute bottom-0;
}

.block-reset-password-wrapper .block-reset-password {
  @apply bg-[#FFEDE7] w-full max-w-[45rem] flex flex-col justify-center items-center z-[1];
}

.block-reset-password-wrapper .block-reset-password .image-logo {
  @apply mb-[1rem];
}

.block-reset-password-wrapper .block-reset-password .image-logo .logo-reset-password {
  @apply w-full h-full max-w-[11.25rem] max-h-[11.25rem];
}

.block-reset-password-wrapper .block-reset-password .title {
  @apply text-[3rem] text-[#FD6B35] font-semibold mb-[1.8rem];
}

.block-reset-password-wrapper .block-reset-password form {
  @apply w-full p-[1.5rem] max-w-[28.125rem];
}

.block-reset-password-wrapper .block-reset-password form .form-password {
  @apply flex items-center gap-2 mb-[1.6rem] rounded-[18px];
}

.block-reset-password-wrapper .block-reset-password form .form-password-label .label {
  @apply text-[0.875rem] font-medium;
}

.block-reset-password-wrapper .block-reset-password form .btn-confirm,
.block-reset-password-wrapper .block-reset-password form .btn-cancel {
  @apply text-[1.25rem] font-medium bg-[#FD6B35] rounded-full border-[2px] border-[#FD6B35] text-white my-[1rem] w-full h-[3.5rem] max-w-[23.875rem];
}

.block-reset-password-wrapper .block-reset-password form .btn-cancel {
  @apply text-[#FD6B35] bg-white;
}

@media screen and (max-width: 1024.98px) {
  .block-reset-password-wrapper .bg-form {
    @apply object-cover h-1/3;
  }
}

@media screen and (max-width: 834.98px) {
  .block-reset-password-wrapper .block-reset-password {
    @apply max-w-full;
  }
}

@media screen and (max-width: 430.98px) {
  .block-reset-password-wrapper .block-reset-password .title {
    @apply text-[1.875rem] mb-0;
  }
}
